import React from "react"
import styles from "./index.module.scss"
import { StaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Img from "gatsby-image"
import ArticleGrid from "../../ArticleGrid"
import Explore from "../../PageComponents/Home/Explore"
import Button from "../../Button"

export default function Article({ images, homeContent }) {
  return (
    <StaticQuery
      query={graphql`
        query Article2PageQuery {
          article1: file(name: { eq: "article-1" }) {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          article3: file(name: { eq: "article-3" }) {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          article4: file(name: { eq: "article-4" }) {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          article5: file(name: { eq: "article-5" }) {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          article6: file(name: { eq: "article-6" }) {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          article7: file(name: { eq: "article-7" }) {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          article8: file(name: { eq: "article-cta" }) {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={data => (
        <div>
          <section>
            <header className={`${styles.header} relative overflow-hidden`}>
              <div className="bg-container bg-container--with-filter absolute w-full h-full top-0 left-0 -z-10">
                <BackgroundImage
                  Tag="div"
                  className="w-full h-full"
                  fluid={data.article3.childImageSharp.fluid}
                  backgroundColor={`#040e18`}
                  loading="eager"
                  critical={true}
                />
              </div>
            </header>
          </section>
          <section className="pt-20 xxl:pt-32 pb-32 xxl:pb-40">
            <div className="w-11/12 md:w-full pl-40 md:px-12 xxl:px-56">
              <p className="large-text light-font mb-8">
                Dimitri Vegas and Like Mike are among the best DJs in the world.
                Their songs and performances combine dynamics, precision and
                beats that whisk their audience from 0 to 60 – just like their
                new tour vehicle: the BMW M8.
              </p>
              <span className="text-base xxl:text-xl">30 March 2020</span>
            </div>
          </section>
          <section className="xxl:pt-32 md:pb-20">
            <div className="image-container w-8/12 md:w-10/12 mb-40 md:mb-0">
              <Img fluid={data.article4.childImageSharp.fluid} />
            </div>
            <div className="flex md:flex-col px-40 md:px-0 xxl:pb-0 xxl:px-56">
              <div className="w-5/12 xxl:w-4/12 pr-20 xxl:pr-0 md:hidden">
                <div className="image-container mb-20 xxl:mb-40">
                  <Img fluid={data.article5.childImageSharp.fluid} />
                </div>
              </div>
              <div className="w-7/12 md:w-full xxl:w-8/12 xxl:px-40 pt-20 xxl:pt-32">
                <div className="block mb-40 md:pl-12 md:w-10/12">
                  <h3 className="quotes md:text-5xl bold-font mb-12">
                    That feeling of being whisked from 0 to 60 is as emotional
                    as it gets.
                  </h3>
                  <p className="text-4xl md:text-2xl light-font mb-8">
                    Dimitri Vegas &amp; Like Mike DJs
                  </p>
                </div>
                  <div className="image-container mb-20 pl-32 hidden md:block">
                    <Img fluid={data.article5.childImageSharp.fluid} />
                  </div>
                <div className="block w-11/12 xxl:w-10/12 md:w-10/12 md:pl-12">
                  <h4 className="headline light-font mb-12 md:text-5xl md:mb-20 md:mt-4">
                    Emotional trigger mechanism
                  </h4>
                  <p className="text-3xl mb-20 md:text-2xl">
                    Dimitri Vegas &amp; Like Mike have traded the rear seats of
                    the BMW M8 for the driver’s and front passenger seats.
                    They’re headed to the Winterland Festival in Norway – and
                    are seizing the opportunity. The key to their songs is the
                    drop, as Dimitri explains. “In electronic music it’s the
                    high point of the song, similar to the chorus in pop music.
                    That moment of brief silence before the beat and melody
                    explode, kick off and let loose again. It’s the anticipation
                    of what’s about to happen. That feeling of being whisked
                    from 0 to 60, when the beat pushes you back into your seat
                    and accelerates forward. Precision, dynamics – it’s pure
                    euphoria.” “It’s actually like the feeling of being behind
                    the wheel of a BMW M8; it’s as emotional as it gets,” Mike
                    interjects, before grinning and hitting the gas pedal. Now
                    it’s the engine’s turn to make sweet music.
                  </p>
                  <span className="text-base xxl:text-xl">30 March 2020</span>
                </div>
              </div>
            </div>
          </section>
          <section className="-mt-64 xxl:mt-0 md:mt-0 flex flex-col">
            <div className="flex px-40 md:px-0 xxl:px-56 md:order-2 md:mt-20">
              <div className="w-5/12 xxl:w-4/12 md:w-full pr-20 md:px-12 xxl:pr-0">
                <h3 className="quotes md:text-5xl bold-font mb-12">
                  That feeling of being whisked from 0 to 60 is as emotional as
                  it gets.
                </h3>
                <p className="text-4xl md:text-2xl light-font mb-8">
                  Dimitri Vegas &amp; Like Mike DJs
                </p>
              </div>
            </div>
            <div className="flex mt-40 md:order-1 md:flex-col">
              <div className="w-6/12 md:w-full md:pr-12">
                <div className={`image-container`}>
                  <Img fluid={data.article1.childImageSharp.fluid} />
                </div>
              </div>
              <div className="w-6/12 md:w-full md:pl-12">
                <div className={`image-container ${styles.imageContainerUp}`}>
                  <Img fluid={data.article6.childImageSharp.fluid} />
                </div>
              </div>
            </div>
          </section>
          <section className="py-40 xxl:py-64">
            <div
              className={` ${styles.banner} bg-container relative w-full h-full top-0 left-0 -z-10`}
            >
              <BackgroundImage
                Tag="div"
                className="w-full h-full"
                fluid={data.article7.childImageSharp.fluid}
                backgroundColor={`#040e18`}
                loading="eager"
                critical={true}
              />
            </div>
            <div className="flex justify-center mt-20 xxl:mt-32">
              <div className="w-8/12 md:w-10/12 text-center">
                <p className="large-text light-font mb-8">
                  Dimitri Vegas and Like Mike are among the best DJs in the
                  world. Their songs and performances combine dynamics,
                  precision and beats that whisk their audience from 0 to 60 –
                  just like their new tour vehicle: the BMW M8.
                </p>
                <span className="text-base xxl:text-xl">30 March 2020</span>
              </div>
            </div>
          </section>
          <section className="pt-20 xxl:pt-40 pb-20 xxl:pb-40">
            <div className="flex justify-start px-40 md:px-12">
              <div className="w-7/12 xxl:w-6/12 md:w-full">
                <h3 className="quotes md:text-5xl bold-font mb-12">
                  That feeling of being whisked from 0 to 60 is as emotional as
                  it gets.
                </h3>
                <p className="text-4xl md:2xl light-font mb-8">
                  Dimitri Vegas &amp; Like Mike DJs
                </p>
              </div>
            </div>
          </section>
          <section>
            <ArticleGrid images={images} />
          </section>
          <section className="pt-32 md:pt-20 xxl:py-56">
            <div className="flex">
              <div className="w-8/12 xxl:w-7/12 pl-40 md:pl-12 xxl:px-56 mb-32 xxl:mb-40">
                <p className="large-text md:text-2xl light-font mb-8">
                  Dimitri Vegas and Like Mike are among the best DJs in the
                  world. Their songs and performances combine dynamics,
                  precision and beats that whisk their audience from 0 to 60 –
                  just like their new tour vehicle: the BMW M8.
                </p>
                <span className="text-base xxl:text-xl">30 March 2020</span>
              </div>
            </div>
          </section>
          <section className="pb-40 xxl:pb-56">
            <div className="flex justify-end pr-32 xxl:px-40 md:pr-12">
              <div className="w-6/12 xxl:w-5/12 md:w-10/12">
                <h3 className="quotes md:text-5xl bold-font mb-12">
                  That feeling of being whisked from 0 to 60 is as emotional as
                  it gets.
                </h3>
                <p className="text-4xl md:text-2xl light-font mb-8">
                  Dimitri Vegas &amp; Like Mike DJs
                </p>
              </div>
              <div className="xxl:w-1/12"></div>
            </div>
          </section>
          <section className="section-cta relative h-screen">
            <div className="bg-container bg-container--with-filter absolute w-full h-full top-0 left-0 -z-10">
              <BackgroundImage
                Tag="div"
                className="w-full h-full"
                fluid={data.article8.childImageSharp.fluid}
                backgroundColor={`#040e18`}
                loading="eager"
                critical={true}
              />
            </div>
            <div className={styles.btnContainer}>
              <Button link="/">Discover More</Button>
            </div>
          </section>
          <div className="border-b border-t border-solid border-white">
            <Explore images={images} home={homeContent} />
          </div>
        </div>
      )}
    />
  )
}
