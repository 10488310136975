import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import Article from "../components/PageComponents/Article/article2"
import localize from "../components/utils/localize.js"

const IndexPage = ({ data, location, pageContext }) => (
  <Layout location={location} context={pageContext}>
    <SEO title="article" />
    <Article images={data.allFile.edges} homeContent={data.sanityHomePage}  />
  </Layout>
)

export default localize(IndexPage)

export const pageQuery = graphql`
  query ArticleTrial2PageQuery {
    allFile(filter: { relativeDirectory: { eq: "Home" } }) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 3080, quality: 50) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    sanityHomePage {
      articleOneContent {
        _type
        en
        tc
      }
      articleOneHoverContent {
        _type
        en
        tc
      }
      articleOneAuthor {
        _type
        en
        tc
      }
      articleOneSubTitle {
        _type
        en
        tc
      }
      articleOneTitle {
        _type
        tc
        en
      }
      articleTwoContent {
        _type
        en
        tc
      }
      articleTwoHoverContent {
        _type
        en
        tc
      }
      articleTwoSmallTitle {
        _type
        en
        tc
      }
      articleTwoSubTitle {
        _type
        en
        tc
      }
      articleTwoTitle {
        _type
        en
        tc
      }
      pageTitle {
        _type
        en
        tc
      }
      introSubTitle {
        _type
        en
        tc
      }
      introParagraph {
        _type
        en
        tc
      }
      scrollForMore {
        _type
        en
        tc
      }
      adventureDescription {
        _type
        en
        tc
      }
      adventureTitle {
        _type
        en
        tc
      }
      adventureDescriptionFull {
        _type
        en
        tc
      }
      embrace {
        _type
        en
        tc
      }
      embraceSectionTitle {
        _type
        en
        tc
      }
      exploreSectionTitle {
        _type
        en
        tc
      }
      exploreSectionParagraph {
        _type
        en
        tc
      }
      freedomDescription {
        _type
        en
        tc
      }
      freedomDescriptionFull {
        _type
        en
        tc
      }
      freedomTitle {
        _type
        en
        tc
      }
      independenceDescription {
        _type
        en
        tc
      }
      independenceDescriptionFull {
        _type
        en
        tc
      }
      independenceTitle {
        _type
        en
        tc
      }
      inspirationDescription {
        _type
        en
        tc
      }
      inspirationDescriptionFull {
        _type
        en
        tc
      }
      inspirationTitle {
        _type
        en
        tc
      }
      progressDescription {
        _type
        en
        tc
      }
      progressDescriptionFull {
        _type
        en
        tc
      }
      progressTitle {
        _type
        en
        tc
      }
      wisdomDescription {
        _type
        en
        tc
      }
      wisdomDescriptionFull {
        _type
        en
        tc
      }
      wisdomTitle {
        _type
        tc
        en
      }
      doYouHaveAPlanToMakeACarPurchase {
        _type
        en
        tc
      }
      embraceSectionParagraph {
        _type
        en
        tc
      }
      embraceSectionRemarks {
        _type
        en
        tc
      }
      formTitle {
        _type
        en
        tc
      }
      howToJoin {
        _type
        en
        tc
      }
      howToJoinStepOne {
        _type
        en
        tc
      }
      howToJoinStepThree {
        _type
        tc
        en
      }
      howToJoinStepTwo {
        _type
        en
        tc
      }
      joinSectionParagraph {
        _type
        en
        tc
      }
      joinSectionTitle {
        _type
        en
        tc
      }
      submit {
        _type
        en
        tc
      }
    }
  }
`
